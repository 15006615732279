export const stepsMetaData = [
  {
    fields: [
      {
        name: 'name',
        required: true,
        rules: 'required|min:2',
        placeholder: 'Имя',
        labelText: 'Назовите себя',
      },
      {
        name: 'telephone',
        required: true,
        rules: 'required|length:12',
        placeholder: 'Телефон',
        labelText: 'Как вам позвонить',
      },
      {
        name: 'email',
        required: true,
        rules: 'required|email',
        placeholder: 'email',
        labelText: 'Куда вам написать',
      },
    ],
  },
  {
    fields: [
      {
        name: 'age',
        required: false,
        placeholder: 'Ответ',
        labelText: 'Ваш возраст?',
      },
      {
        name: 'marital_status',
        required: false,
        placeholder: 'Ответ',
        labelText: 'Семейное положение?',
      },
      {
        name: 'children',
        required: false,
        placeholder: 'Ответ',
        labelText: 'Есть ли у вас дети?',
      },
      {
        name: 'allergy',
        required: false,
        placeholder: 'Ответ',
        labelText: 'Есть ли у вас или членов вашей семьи аллергия на животных?',
      },
      {
        name: 'experience',
        required: false,
        placeholder: 'Ответ',
        labelText: 'Есть ли у вас опыт в содержании кошек?',
      },
      {
        name: 'having_pat',
        required: false,
        placeholder: 'Ответ',
        labelText: 'Живёт ли сейчас с вами питомец? Если да, то какой?',
      },
    ],
  },
  {
    fields: [
      {
        name: 'living_space',
        required: false,
        placeholder: 'Ответ',
        labelText: 'У вас собственная жилплощадь или съёмная?',
      },
      {
        name: 'living_space_type',
        required: false,
        placeholder: 'Ответ',
        labelText: 'Частный дом или квартира?',
      },
      {
        name: 'balcony_type',
        required: false,
        placeholder: 'Ответ',
        labelText: 'Застеклён ли балкон (при наличии)?',
      },
      {
        name: 'presence_screens',
        required: false,
        placeholder: 'Ответ',
        labelText: 'Сетки на окнах есть или готовы поставить?',
      },
    ],
  },
  {
    fields: [
      {
        name: 'readiness_vaccinate',
        required: false,
        placeholder: 'Ответ',
        labelText: 'Вы готовы обеспечить животному ежегодную вакцинацию \nи ветеринарный осмотр?',
      },
      {
        name: 'self_walking',
        required: false,
        placeholder: 'Ответ',
        labelText: 'Самовыгул животного запрещён. Вы согласны?',
      },
      {
        name: 'pet_care',
        required: false,
        placeholder: 'Ответ',
        labelText: 'Есть с кем оставить животное на время отъезда?',
      },
    ],
  },
];
