<template>
  <div class="application">
    <div class="application__card" ref="cardRef">
      <h2 class="application__heading">
        {{ stepHeading }}
      </h2>
      <p class="application__description">
        {{ stepDescription }}
      </p>
      <div class="application__steps">
        <div
          :class="['application__step-number', { 'application__step-number--active': step - 1 === currentStepNumber }]"
          v-for="step of 4"
          :key="step"
        >
          {{ step }}
        </div>
      </div>
      <div class="application__info" v-if="!isFirstStep">Кстати, можете отвечать не на все вопросы.</div>
      <ValidationObserver v-slot="{ handleSubmit, invalid }" tag="div">
        <form class="application__form app-form" novalidate @submit.prevent="handleSubmit(onSubmit)">
          <div
            class="app-form__step"
            v-for="(step, index) of stepsMetaData"
            :key="index"
            v-show="showCurrentStep(index)"
          >
            <div v-for="field of step.fields" :key="field.name">
              <ValidationProvider
                v-if="field.required"
                class="app-form__field"
                tag="div"
                :name="field.placeholder"
                :rules="field.rules"
                v-slot="{ errors }"
                :mode="formData[field.name] ? 'eager' : 'passive'"
              >
                <label :for="field.name" class="app-form__label app-form__label--required">
                  {{ field.labelText }}
                </label>
                <input
                  v-if="field.name !== 'telephone'"
                  type="text"
                  :id="field.name"
                  :name="field.name"
                  :class="['app-form__input', { 'app-form__input--error': errors[0] }]"
                  v-model="formData[field.name]"
                  :placeholder="field.placeholder"
                />
                <input
                  v-else
                  v-mask="'+7##########'"
                  type="text"
                  :id="field.name"
                  :name="field.name"
                  :class="['app-form__input', { 'app-form__input--error': errors[0] }]"
                  v-model="formData[field.name]"
                  :placeholder="field.placeholder"
                />
                <span class="app-form__error" v-if="errors[0]">{{ errors[0] }}</span>
                <div class="app-form__info" v-if="field.name === 'email'">*обязательные поля</div>
              </ValidationProvider>
              <div class="app-form__field" v-else>
                <label :for="field.name" class="app-form__label">
                  {{ field.labelText }}
                </label>
                <input
                  type="text"
                  :id="field.name"
                  :name="field.name"
                  class="app-form__input"
                  v-model="formData[field.name]"
                  :placeholder="field.placeholder"
                />
              </div>
            </div>
          </div>
          <div class="app-form__buttons">
            <div class="app-form__back" v-if="!isFirstStep" @click="prevStep">
              <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.1353 22.7387L15.3097 26L28 13.0129L15.3343 0L12.1538 3.25498L19.3936 10.6932H0V15.3009H19.403L12.1353 22.7387Z"
                  fill="#C5FF71"
                />
              </svg>
            </div>
            <FrrButton
              :type="buttonType"
              :text="buttonText"
              @click="nextStep"
              :key="currentStepNumber"
              :disabled="invalid"
              :loading="loading"
            />
          </div>
        </form>
      </ValidationObserver>
      <div class="application__privacy" v-if="isFirstStep">
        Заполнение заявки на данном сайте не требует предоставления персональных данных (таких как: ФИО полностью, адрес
        проживания, сведения о семейном положении, электронная почта, содержащая в своём адресе персональные данные), в
        связи с чем при заполнении заявки вы обязуетесь не вводить никаких ваших персональных данных. В пункте «Как к
        вам обращаться» допускается указывать вымышленное имя, не являющееся вашим реальным именем.
      </div>
    </div>
    <FrrModal v-if="isModalShown" :forForm="true" @close="closeModal">
      <div class="application__error" v-if="isHashRejected">
        <div class="application__err-heading">Срок заявки истёк.</div>
        <p class="application__err-text">
          Уважаемый {{ name }}, срок действия вашей заявки истёк, поэтому, если вы всё ещё хотите забрать котика, вам
          нужно отправить новую. Если у вас возникнут вопросы, пишите нам по адресу:
          <a href="mailto:frrfrr.home@9pandas.ru" class="apllication__mail">frrfrr.home@9pandas.ru</a>
        </p>
      </div>
      <div class="application__error" v-if="hashError || serverError">
        <div class="application__err-heading">Что-то пошло не так.</div>
        <p class="application__err-text">
          {{ errorText }} <a href="mailto:frrfrr.home@9pandas.ru" class="apllication__mail">frrfrr.home@9pandas.ru</a>
        </p>
        <div class="application__err-btn">
          <FrrButton :isStreched="true" text="Хорошо" @click="closeModal" />
        </div>
      </div>
    </FrrModal>
  </div>
</template>

<script>
import FrrButton from '@/components/FrrButton/FrrButton.vue';
import FrrModal from '@/components/FrrModal/FrrModal.vue';
import { getApplicationHashStatus, sendApplicationRequest } from '@/api';
import { stepsMetaData } from './cat-application.fixtures';
import { VueMaskDirective } from 'v-mask';
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate/dist/vee-validate';
import { required, email, length, min } from 'vee-validate/dist/rules';
import ru from 'vee-validate/dist/locale/ru.json';
localize('ru', ru);
extend('email', email);
extend('required', required);
extend('length', length);
extend('min', min);

export default {
  name: 'CatApplication',
  components: {
    ValidationObserver,
    ValidationProvider,
    FrrButton,
    FrrModal,
  },
  directives: {
    mask: VueMaskDirective,
  },
  data: () => ({
    applicationHash: '',
    currentStepNumber: 0,
    formData: {
      name: '',
      telephone: '',
      email: '',
      age: '',
      marital_status: '',
      children: '',
      allergy: '',
      experience: '',
      having_pat: '',
      living_space: '',
      living_space_type: '',
      balcony_type: '',
      presence_screens: '',
      readiness_vaccinate: '',
      self_walking: '',
      pet_care: '',
    },
    stepsHeadings: ['Персональные данные', 'Личная информация', 'Жилищные условия', 'Безопасность и здоровье питомца'],
    stepsMetaData,
    loading: false,
    isModalShown: false,
    serverError: false,
    hashError: false,
    isHashRejected: false,
    name: 'пользователь',
  }),
  computed: {
    stepDescription() {
      return this.currentStepNumber === 0
        ? 'Кто вы и как с вами связаться?'
        : 'Мы волнуемся за наших питомцев, \nпоэтому просим Вас ответить на вопросы.';
    },
    stepHeading() {
      return this.stepsHeadings[this.currentStepNumber];
    },
    isFirstStep() {
      return this.currentStepNumber === 0;
    },
    isLastStep() {
      return this.currentStepNumber === this.stepsMetaData.length - 1;
    },
    buttonType() {
      return this.isLastStep ? 'submit' : 'button';
    },
    buttonText() {
      return this.isLastStep ? 'Отправить' : 'Далее';
    },
    errorText() {
      return this.hashError
        ? 'Попробуйте обновить страницу или напишите нам на почту'
        : 'Попробуйте отправить заявку позже или напишите нам на почту';
    },
  },
  created() {
    this.getHash();
    this.checkHashOnCreated();
    this.getHashStatus();
  },
  methods: {
    getHash() {
      this.applicationHash = this.$route.params.id;
    },
    navigateToHome() {
      this.isModalShown = false;
      this.$router.replace({ name: 'home' });
    },
    checkHashOnCreated() {
      !this.applicationHash && this.navigateToHome();
    },
    getHashStatus() {
      getApplicationHashStatus(this.applicationHash)
        .then((response) => {
          if (response.status === 'error') {
            this.isModalShown = true;
            this.isHashRejected = true;
            this.name = response.cat_application.name;
          }
        })
        .catch((error) => {
          console.error(error);
          this.isModalShown = true;
          this.hashError = true;
        });
    },
    onSubmit() {
      if (this.loading) return;
      this.loading = true;
      sendApplicationRequest(this.formData, this.applicationHash)
        .then(() => this.$router.replace({ name: 'home' }))
        .catch((error) => {
          console.error(error);
          this.serverError = true;
          this.isModalShown = true;
        })
        .finally(() => (this.loading = false));
    },
    nextStep() {
      if (this.currentStepNumber >= this.stepsMetaData.length - 1) {
        this.currentStepNumber = this.stepsMetaData.length - 1;
        return;
      }
      this.currentStepNumber++;
      this.scrollToCardTop();
    },
    prevStep() {
      if (this.currentStepNumber <= 0) {
        this.currentStepNumber = 0;
        return;
      }
      this.currentStepNumber--;
      this.scrollToCardTop();
    },
    showCurrentStep(index) {
      return this.currentStepNumber === index;
    },
    scrollToCardTop() {
      const top = window.scrollY - Math.abs(this.$refs.cardRef?.getBoundingClientRect().top);

      window.scroll({
        left: 0,
        top,
        behavior: 'smooth',
      });
    },
    closeModal() {
      this.isModalShown = false;
      if (this.isHashRejected || this.hashError) {
        this.navigateToHome();
      }
      this.serverError = false;
      this.hashError = false;
      this.isHashRejected = false;
    },
  },
};
</script>

<style scoped lang="sass" src="./CatApplication.sass"></style>
